import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import "./scss/app.scss";

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons)

Vue.component("SectionHeader", require("@/components/SectionHeader").default);
Vue.component("SectionTitle", require("@/components/SectionTitle").default);

new Vue({
  router,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
